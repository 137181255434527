import { Link as ChakraLink } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

type ChakraLinkProps = Parameters<typeof ChakraLink>[0];
type RouterLinkProps = Parameters<typeof RouterLink>[0];

type CombinedLinkProps = ChakraLinkProps & RouterLinkProps;

export function Link(props: CombinedLinkProps) {
  return (
    <ChakraLink asChild {...props}>
      <RouterLink {...props}></RouterLink>
    </ChakraLink>
  );
}
