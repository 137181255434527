import { Button, Flex, For, IconButton } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "@/shared/i18n";
import { Link } from "@/shared/ui";
import { getButtons, Paths } from "./getButtons";
import { locales } from "./locales";

export function MobileNavBar(paths: Paths) {
  const buttons = getButtons(paths);

  const t = useTranslation(locales);

  const location = useLocation();

  return (
    <Flex alignItems="flex-start" justify="space-around" padding="2" bg="white">
      <For each={buttons}>
        {(button, index) => (
          <Link
            to={button.path}
            key={index}
            title={t(button.locale)}
            w="full"
            justifyContent="center"
          >
            {button.main ? (
              <Button>
                <button.icon size="1.5rem" />
              </Button>
            ) : (
              <IconButton
                variant="ghost"
                color={
                  location.pathname === button.path ? "primary" : "secondary"
                }
              >
                <button.icon size="1.5rem" />
              </IconButton>
            )}
          </Link>
        )}
      </For>
    </Flex>
  );
}
