import EventEmitter from "events";
import axios, { AxiosError, AxiosInstance, InternalAxiosRequestConfig } from "axios";
import { ApiError } from "./ApiError";

class AuthRefresher extends EventEmitter {
  private refreshPromise: Promise<void> | null = null;

  public async refreshToken(): Promise<void> {
    if (!this.refreshPromise) {
      this.refreshPromise = axios.post("/api/v1/auth/refresh", {}, { withCredentials: true })
        .then(() => {
          this.refreshPromise = null;
        })
        .catch((error) => {
          this.refreshPromise = null;
          this.emit('refreshError', error);
          throw error;
        });
    }

    return this.refreshPromise;
  }
}

interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  _retry?: boolean;
}

export const buildApiInstance = (
  onAuthExpired: () => void
): AxiosInstance => {
  const instance = axios.create({
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  const authRefresher = new AuthRefresher();
  authRefresher.on('refreshError', onAuthExpired);

  instance.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
      const originalRequest: CustomAxiosRequestConfig | undefined = error.config;

      if (originalRequest && error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          await authRefresher.refreshToken();
          
          return instance.request(originalRequest);
        } catch (_refreshError) {
          onAuthExpired();
          throw new ApiError(`Handled Axios Error: unauthorized`, 'unauthorized', error);
        }
      }
      return Promise.reject(error);
    }
  );

  return instance;
};
