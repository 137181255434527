import { QueryClient } from '@tanstack/react-query';
import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { HomePage } from "@/pages/home";
import { SignInPage } from "@/pages/sign-in";
import { TransactionNewPage } from "@/pages/transaction-new";
import { Paths } from '@/widgets/navbar';
import { buildApiInstance, ApiProvider } from "@/shared/api";
import { TranslationProvider } from '@/shared/i18n';
import { reportWebVitals, unregisterServiceWorker } from "@/shared/lib";
import { ChakraProvider, Toaster, system as themeSystem } from "@/shared/ui";
import { BaseLayout } from './layouts/BaseLayout';
import { NavigationLayout } from "./layouts/NavigationLayout";

const navigationPaths: Paths = {
  home: "/",
  transactions: "/transactions",
  newTransaction: "/transactions/new",
  profile: "/profile",
  settings: "/settings",
};

const router = createBrowserRouter([
  {
    element: <NavigationLayout {...navigationPaths}/>,
    children: [
      {
        path: "/",
        element: <HomePage></HomePage>,
      },
    ],
  },
  { 
    element: <BaseLayout />,
    children: [
      {
        path: "/sign-in",
        element: <SignInPage onSuccess={() => router.navigate("/")}></SignInPage>,
      },
      {
        path: "/transactions/new",
        element: <TransactionNewPage></TransactionNewPage>,
      },
    ]
  },
]);

const queryClient = new QueryClient();
const axiosInstance = buildApiInstance(() => {
  router.navigate("/sign-in");
});

export function initApp() {
  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
  );
  root.render(
    <React.StrictMode>
      <ChakraProvider system={themeSystem}>
        <TranslationProvider availableLocales={['en', 'ru']} fallbackLocale="ru">
          <ApiProvider axiosInstance={axiosInstance} queryClient={queryClient}>
            <RouterProvider router={router} />
          </ApiProvider>
        </TranslationProvider>
        <Toaster />
      </ChakraProvider>
    </React.StrictMode>,
  );

  // If you want your app to work offline and load faster, you can change
  // unregisterServiceWorker() to registerServiceWorker() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://cra.link/PWA
  unregisterServiceWorker();

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}
