import { deepmergeCustom } from "deepmerge-ts"; 

export const mergeAndModify = deepmergeCustom({
  mergeRecords: (values, utils, meta) => {
    const [target, source] = values;
    const targetCopy = { ...target };
    const { __delete, ...sourceCopy } = source;

    if(__delete && Array.isArray(__delete)) {
      __delete.forEach(key => delete targetCopy[key]);
    }

    return utils.defaultMergeFunctions.mergeRecords([targetCopy, sourceCopy], utils, meta);
  },
});