import { AxiosError, AxiosResponse } from "axios";
import { getTypedEntries } from "@/shared/lib";
import { toaster } from "@/shared/ui";

export class ApiError extends Error {
  public error: Error | null;
  public errorCode: string | null;

  constructor(message: string, errorCode: string | null = null, error: Error | null = null) {
    super(message);
    this.name = 'ApiError';
    this.errorCode = errorCode;
    this.error = error;

    if (error) {
      this.stack = error.stack;
    }
  }
}

type ErrorHandler = (response?: AxiosResponse, error?: AxiosError) => boolean;
export type ErrorHandlers<TErrorCodes extends string> = Record<TErrorCodes, ErrorHandler>;

export function createErrorHandlers<T extends Record<string, ErrorHandler>>(handlers: T) {
  return handlers;
}

type GetErrorCode = <T extends ErrorHandlers<string>>(
  handlers: T,
  response?: AxiosResponse,
  error?: AxiosError,
) => keyof T | null;

export const getErrorCode: GetErrorCode = (handlers, response, error) => {
  return getTypedEntries(handlers).find(([_, handler]) => handler(response, error))?.[0] || null;
};

export const handleAxiosError = <TErrorCodes extends string>(error: Error, errorHandlers: ErrorHandlers<TErrorCodes>) => {
  if (error instanceof ApiError) throw error;
  if (error instanceof AxiosError) handleResponseError(errorHandlers, error?.response, error);

  toaster.create({
    description: "An unexpected error occurred",
    type: "error",
  });
  throw new ApiError('Unhandled Axios Error', 'unexpectedError', error);
};

export const handleResponseError = <TErrorCodes extends string>(errorHandlers: ErrorHandlers<TErrorCodes>, response?: AxiosResponse, error?: AxiosError) => {
  const handledErrorCode = getErrorCode(errorHandlers, response, error);
  if (handledErrorCode) {
    throw new ApiError(`Handled Response Error: ${handledErrorCode}`, handledErrorCode, error);
  }
};

export type defaultErrorCodes = 'unexpectedErrror' | keyof typeof defaultHandlers;

export const defaultHandlers = createErrorHandlers({
  networkError: (_response, error) => error?.code === 'ERR_NETWORK',
});