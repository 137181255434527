"use client"

import { ChakraProvider, SystemContext } from "@chakra-ui/react";
import { ColorModeProvider } from "./components/ColorMode";

interface ProviderProps extends React.PropsWithChildren {
  system: SystemContext;
}

export function Provider({ children, system }: ProviderProps) {
  return (
    <ChakraProvider value={system}>
      <ColorModeProvider>{children}</ColorModeProvider>
    </ChakraProvider>
  );
}
