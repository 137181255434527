import { useApiQuery } from "@/shared/api";

export interface UserDto {
  id: number;
  nickname: string;
  initials: string;
}

export function useGetCurrentUser() {
  return useApiQuery<UserDto>(
    {
      axiosOptions: { url: "/api/v1/users/current" },
      queryKey: ["currentUser"],
    },
  );
}