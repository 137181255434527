import { useReducer, useMemo, useCallback } from 'react';
import { greatestCommonDivisorArray as gcdArray } from "@/shared/lib";

type State = {
  shares: Map<number, number>;
  ratios: Map<number, number>;
  touchedUserIds: number[];
  factor: number;
};

type Action =
  | { type: 'CHANGE_SHARE'; userId: number; newShare: number }
  | { type: 'CHANGE_RATIO'; userId: number; newRatio: number };

const initialState = (initialTotalSum: number, userIds: number[], factor = 100): State => {
  const initialShare = initialTotalSum / userIds.length;
  const shares = new Map<number, number>();
  const ratios = new Map<number, number>();

  userIds.forEach((id) => {
    shares.set(id, initialShare);
    ratios.set(id, 1);
  });

  return {
    shares,
    ratios,
    touchedUserIds: [],
    factor,
  };
};

const calculateRatios = (shares: Map<number, number>, factor = 100) => {
  const gcdValue = gcdArray(Array.from(shares.values()));
  const newRatios = new Map<number, number>();
  shares.forEach((share, userId) => {
    const newRatio = Math.round((share / gcdValue) * factor) / factor;
    newRatios.set(userId, newRatio);
  });
  return newRatios;
};

const distributeRemainingSum = (
  totalSum: number,
  shares: Map<number, number>,
  touchedUserIds: number[],
  factor = 100
) => {
  const untouchedUserIds = Array.from(shares.keys()).filter(
    (id) => !touchedUserIds.includes(id)
  );

  const distributedSum = touchedUserIds.reduce(
    (acc, userId) => acc + (shares.get(userId) || 0),
    0
  );
  const remainingSum = totalSum - distributedSum;

  const perUserShare = Math.round((remainingSum / untouchedUserIds.length) * factor) / factor;

  untouchedUserIds.forEach((userId) => {
    shares.set(userId, perUserShare);
  });

  const currentSum = Array.from(shares.values()).reduce((acc, share) => acc + share, 0);
  const difference = Math.round((totalSum - currentSum) * factor) / factor;

  if (difference !== 0) {
    const randomIndex = Math.floor(Math.random() * untouchedUserIds.length);
    const randomUserId = untouchedUserIds[randomIndex];
    const adjustedShare = (shares.get(randomUserId) || 0) + difference;
    
    shares.set(randomUserId, Math.round(adjustedShare * factor) / factor);
  }

  return shares;
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'CHANGE_SHARE': {
      const { userId, newShare } = action;
      const newShares = new Map(state.shares);
      const totalSum = Array.from(newShares.values()).reduce((acc, share) => acc + share, 0);
      newShares.set(userId, newShare);

      const newTouchedUserIds = state.touchedUserIds.includes(userId)
        ? state.touchedUserIds
        : [...state.touchedUserIds, userId];

      const updatedShares = distributeRemainingSum(totalSum, newShares, newTouchedUserIds, state.factor);
      const newRatios = calculateRatios(updatedShares, state.factor);

      return {
        ...state,
        shares: updatedShares,
        ratios: newRatios,
        touchedUserIds: newTouchedUserIds,
      };
    }

    case 'CHANGE_RATIO': {
      const { userId, newRatio } = action;
      const newRatios = new Map(state.ratios);
      newRatios.set(userId, newRatio);

      const totalRatios = Array.from(newRatios.values()).reduce((acc, ratio) => acc + ratio, 0);
      const totalSum = Array.from(state.shares.values()).reduce((acc, share) => acc + share, 0);
      const ratioUnitShare = totalSum / totalRatios;

      const newShares = new Map<number, number>();
      newRatios.forEach((ratio, id) => {
        newShares.set(id, ratio * ratioUnitShare);
      });

      const newTouchedUserIds = state.touchedUserIds.includes(userId)
        ? state.touchedUserIds
        : [...state.touchedUserIds, userId];

      return {
        ...state,
        shares: newShares,
        ratios: newRatios,
        touchedUserIds: newTouchedUserIds,
      };
    }

    default:
      return state;
  }
};

export const useSharesReducer = (initialTotalSum: number, userIds: number[], precision = 2) => {
  const factor = useMemo(() => Math.pow(10, precision), [precision]);

  const initialStateMemoized = useMemo(() => initialState(initialTotalSum, userIds, factor), [initialTotalSum, userIds, factor]);

  const [state, dispatch] = useReducer(reducer, initialStateMemoized);

  const changeShare = useCallback((userId: number, newShare: number) => {
    dispatch({ type: 'CHANGE_SHARE', userId, newShare });
  }, []);

  const changeRatio = useCallback((userId: number, newRatio: number) => {
    dispatch({ type: 'CHANGE_RATIO', userId, newRatio });
  }, []);

  const totalSum = useMemo(() => {
    return Array.from(state.shares.values()).reduce((acc, share) => acc + share, 0);
  }, [state.shares]);

  return { shares: state.shares, ratios: state.ratios, changeShare, changeRatio, totalSum };
};
