import { defineRecipe } from "@chakra-ui/react";

export const inputRecipe = defineRecipe({
  className: "chakra-input",
  base: {
    width: "100%",
    minWidth: 0,
    outline: 0,
    position: "relative",
    appearance: "none",
    textAlign: "start",
    _disabled: {
      layerStyle: "disabled"
    },
    height: "var(--input-height)",
    "--focus-color": "colors.focusRing",
    "--error-color": "colors.border.error",
    textStyle: "sm",
    px: "3",
    py: "2",
    borderRadius: "md",
    "--input-height": "sizes.10",
    scrollPaddingBottom: "2",
    borderWidth: "1px",
    borderColor: "transparent",
    bg: "bg.subtle",
    focusVisibleRing: "inside",
    _focusVisible: {
      bg: "bg.subtle"
    },
    _invalid: {
      focusRingColor: "var(--error-color)",
      borderColor: "var(--error-color)"
    },
    _autofill: {
      bg: "bg.subtle"
    }
  }
});