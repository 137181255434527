import {
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { AxiosInstance } from "axios";
import React, { createContext, useContext, ReactNode} from "react";

interface ApiContextType {
  axiosInstance: AxiosInstance;
}

const ApiContext = createContext<ApiContextType | undefined>(undefined);

export const useApiContext = (): ApiContextType => {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error("useApiContext must be used within an ApiProvider");
  }
  return context;
};

interface ApiProviderProps {
  axiosInstance: AxiosInstance;
  queryClient: QueryClient;
  children: ReactNode;
}

export const ApiProvider: React.FC<ApiProviderProps> = ({
  axiosInstance,
  queryClient,
  children,
}) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ApiContext.Provider value={{ axiosInstance }}>
        {children}
      </ApiContext.Provider>
    </QueryClientProvider>
  );
};