import { FaPlus } from "react-icons/fa6";
import { IoWalletOutline, IoSettingsOutline } from "react-icons/io5";
import { LuUser2 } from "react-icons/lu";
import { RiHome5Line } from "react-icons/ri";

export interface Paths {
  home: string;
  transactions: string;
  newTransaction: string;
  profile: string;
  settings: string;
}

export const getButtons = (paths: Paths) => [
  { icon: RiHome5Line, path: paths.home, locale: "home" },
  { icon: IoWalletOutline, path: paths.transactions, locale: "transactions" },
  { icon: FaPlus, path: paths.newTransaction, locale: "newTransaction", main: true },
  { icon: LuUser2, path: paths.profile, locale: "profile" },
  { icon: IoSettingsOutline, path: paths.settings, locale: "settings" },
];