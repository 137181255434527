export const locales = {
  en: {
    title: "Login to Skatilsya Wallet",
    nickname: "Nickname",
    password: "Password",
    buttonText: "Login",
    errors: {
      unprocessableEntity: 'Invalid nickname-password pair'
    }
  },
  ru: {
    title: "Войти в Skatilsya Wallet",
    nickname: "Никнейм",
    password: "Пароль",
    buttonText: "Войти",
    errors: {
      unprocessableEntity: 'Неверная пара никнейм-пароль'
    }
  },
};