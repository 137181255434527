import delve from 'dlv';
import { createContext, useContext, useState, ReactNode } from 'react';

interface TranslationContextProps {
  currentLocale: string;
  switchLocale: (newLocale: string) => void;
}

interface LanguageLocales {
  [key: string]: string | LanguageLocales;
}

type Locales = {
  [key: string]: LanguageLocales;
};

const TranslationContext = createContext<TranslationContextProps | undefined>(undefined);

interface TranslationProviderProps {
  children: ReactNode;
  fallbackLocale?: string;
  availableLocales: string[];
}

export function TranslationProvider({ children, fallbackLocale = 'en', availableLocales = ['en'] }: TranslationProviderProps) {
  const browserLocale = navigator.language.split('-')[0];
  const initialLocale = availableLocales.includes(browserLocale) ? browserLocale : fallbackLocale;
  const [locale, setLocale] = useState<string>(initialLocale);

  const switchLocale = (newLocale: string) => {
    if (availableLocales.includes(newLocale)) {
      setLocale(newLocale);
    } else {
      console.warn(`Locale '${newLocale}' is not available.`);
    }
  };

  return (
    <TranslationContext.Provider value={{ currentLocale: locale, switchLocale }}>
      {children}
    </TranslationContext.Provider>
  );
}

type LocaleVariables = { [key: string]: string | number };

function replaceTemplateLiterals(translation: string, variables: LocaleVariables): string {
  return translation.replace(/{(\w+)}/g, (match, varKey) => {
    return varKey in variables ? String(variables[varKey]) : match;
  });
}

export const useLocale = (): TranslationContextProps => {
  const context = useContext(TranslationContext);
  if (!context) {
    throw new Error('useLocale must be used within a TranslationProvider');
  }
  return context;
};

export function useTranslation(locales: Locales) {
  const { currentLocale } = useLocale();

  const t = (key: string, variables?: LocaleVariables): string => {
    const translation = delve(locales[currentLocale], key);

    if (translation === undefined) {
      console.warn(`Translation for key '${key}' not found in locale '${currentLocale}'`);
      return key;
    }

    if (typeof translation === 'string') {
      return variables ? replaceTemplateLiterals(translation, variables) : translation;
    }

    console.warn(`Translation for key '${key}' in locale '${currentLocale}' is not a string.`);
    return key;
  };

  return t;
}