import { useCallback, useMemo, useState } from "react"; 
import { useChakraUiTextWidthMeasure } from "@/shared/lib";

export const useRatiosDynamicInputWidth = (initialiRatios: Map<number, number>) => {
  const getWidthForText = useChakraUiTextWidthMeasure();

  const [ratioTexts, setRatioTexts] = useState(new Map(
    [...initialiRatios].map(([key, value]) => [key, String(value)])
  ));

  const ratioWidth = useMemo(() => { 
    return Math.max(
      ...Array.from(ratioTexts.values()).map((ratioText) => getWidthForText(ratioText)) 
    );
  }, [ratioTexts, getWidthForText]);

  const changeRatioText = useCallback((userId: number, newRatioText: string) => {
    setRatioTexts((prevRatioTexts) => {
      const newRatioTexts = new Map(prevRatioTexts);
      newRatioTexts.set(userId, newRatioText);
      return newRatioTexts;
    });
  }, []);

  return { ratioWidth, changeRatioText };
};
