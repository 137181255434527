import { Fieldset, Text } from '@chakra-ui/react';
import { useMemo } from 'react';
import { participants as importParticipants } from "../model/participants";
import { useRatiosDynamicInputWidth } from './useRatiosDynamicInputWidth';
import { UserInput } from "./UserInput";
import { useSharesReducer } from './useSharesReducer';

interface ParticipantsProps {
  initialTotalSum: number;
}

export function Shares({ initialTotalSum }: ParticipantsProps) {
  const userIds = useMemo(() => importParticipants.map((p) => p.id), []);

  const { shares, ratios, changeShare, changeRatio, totalSum } = useSharesReducer(initialTotalSum, userIds);

  const { ratioWidth, changeRatioText } = useRatiosDynamicInputWidth(ratios);

  return (
    <Fieldset.Root>
      <Text>{totalSum}</Text>
      {importParticipants.map((user) => (
        <UserInput
          key={user.id}
          hue={user.hue}
          name={user.name}
          share={shares.get(user.id) || 0}
          ratio={(ratios.get(user.id) || 0)}
          ratioWidth={ratioWidth}
          onValueChange={(newValue) => changeShare(user.id, newValue)}
          onRatioChange={(newRatio) => changeRatio(user.id, newRatio)}
          onRatioStringChange={(newRatio) => changeRatioText(user.id, newRatio)} 
        />
      ))}
    </Fieldset.Root>
  );
}
