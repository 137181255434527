import { defineRecipe } from "@chakra-ui/react";

export const buttonRecipe = defineRecipe({
  className: "chakra-button",
  base: {
    display: "inline-flex",
    appearance: "none",
    alignItems: "center",
    justifyContent: "center",
    userSelect: "none",
    position: "relative",
    whiteSpace: "nowrap",
    verticalAlign: "middle",
    cursor: "button",
    flexShrink: "0",
    outline: "0",
    lineHeight: "1.2",
    isolation: "isolate",
    fontWeight: "medium",
    transitionProperty: "common",
    transitionDuration: "moderate",
    focusVisibleRing: "outside",
    _disabled: {
      layerStyle: "disabled"
    },
    _icon: {
      fontSize: "1em",
      flexShrink: 0
    },
  },
  variants: {
    size: {
      sm: {
        gap: "2",
        h: "8",
        minW: "8",
        textStyle: "sm",
        borderRadius: "sm",
        px: "3"
      },
      md: {
        gap: "2",
        h: "10",
        minW: "10",
        borderRadius: "md",
        textStyle: "sm",
        px: "4"
      },
    },
    variant: {
      primary: {
        bg: "primary",
        color: "white",
        _hover: {
          bg: "primary/90"
        },
        _expanded: {
          bg: "primary/90"
        }
      },
      secondary: {
        borderWidth: "1px",
        borderColor: "primary/90",
        color: "primary",
        _hover: {
          bg: "bg.subtle"
        },
        _expanded: {
          bg: "bg.subtle"
        }
      },
      danger: {
        borderWidth: "1px",
        borderColor: "fg.error/80",
        color: "fg.error",
        fontWeight: "bold",
        _hover: {
          bg: "bg.subtle"
        },
        _expanded: {
          bg: "bg.subtle"
        }
      },
      ghost: {
        color: "colorPalette.fg",
        _hover: {
          bg: "bg"
        },
        _expanded: {
          bg: "bg"
        }
      },
    }
  },
  defaultVariants: {
    variant: "primary",
    size: "md"
  }
});