import { UserDto } from "@/entities/user";
import { useApiMutation, MutationOptions, createErrorHandlers } from "@/shared/api";

interface SingInRequestDto {
  nickname: string;
  password: string;
}

interface AuthDataDto {
  expAccess: number;
  data: UserDto;
}

const errorHandlers = createErrorHandlers({
  unprocessableEntity: (response) => response?.status === 422,
});

export function useSignIn(
  mutationOptions?: Omit<MutationOptions<AuthDataDto, SingInRequestDto>, 'axiosOptions'>,
) {
  return useApiMutation<AuthDataDto, SingInRequestDto, keyof typeof errorHandlers>(
    {
      axiosOptions: (credentials) => ({
        url: "/api/v1/auth/sign-in",
        method: "POST",
        data: credentials,
      }),
      errorHandlers,
      ...mutationOptions,
    },
  );
}
