import { QueryKey, UseQueryOptions, useQuery } from "@tanstack/react-query";
import { AxiosRequestConfig } from "axios";
import { useMemo } from "react";
import { ApiError, ErrorHandlers, defaultErrorCodes, defaultHandlers, handleAxiosError, handleResponseError } from "./ApiError";
import { useApiContext } from "./ApiProvider";

export type QueryOptions<
  TQueryFnData = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
> = Omit<
  UseQueryOptions<TQueryFnData, ApiError, TData, TQueryKey>,
  "queryFn"
> & { axiosOptions: AxiosRequestConfig, errorHandlers?: ErrorHandlers<string> };

export function useApiQuery<
  TQueryFnData = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
  TErrorCodes extends string = string,
>(
  queryOptions: QueryOptions<TQueryFnData, TData, TQueryKey>,
) {
  const { axiosInstance } = useApiContext();
  const { axiosOptions, errorHandlers: customErrorHandlers = {} as ErrorHandlers<TErrorCodes>, ...queryOptionsRest } = queryOptions || {};

  const errorHandlers = useMemo(() => Object.assign({}, defaultHandlers, customErrorHandlers), [customErrorHandlers]);

  const query = useQuery({
    queryFn: async () => {
      try {
        const response = await axiosInstance(axiosOptions);
        handleResponseError<TErrorCodes>(Object.assign({}, defaultHandlers, errorHandlers), response);
        return response.data as TQueryFnData;
      } catch (error) {
        handleAxiosError<TErrorCodes>(error as Error, Object.assign({}, defaultHandlers, errorHandlers));
        throw error;
      }
    },
    ...queryOptionsRest,
  });

  const errorCode = useMemo(() => query.error?.errorCode || null, [query.error]); 

  return {
    isErrorHandled: !!errorCode,
    errorCode: errorCode as TErrorCodes | defaultErrorCodes,
    ...query,
  };
}