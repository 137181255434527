import { Box, Flex, Container } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { MobileNavBar, DesktopNavBar } from "@/widgets/navbar";
import { Paths } from "@/widgets/navbar";

export function NavigationLayout(paths: Paths) {
  return (
    <>
      {/* Desktop Layout */}
      <Flex minH="100vh" hideBelow="desktop">
        <Box as="aside" shadow="lg">
          <DesktopNavBar {...paths} />
        </Box>
        <Box flex="1">
          <Outlet />
        </Box>
      </Flex>

      {/* Mobile Layout */}
      <Box hideFrom="desktop">
        <Container maxW="desktop">
          <Outlet />
        </Container>
        <Box position="fixed" left="0" bottom="0" right="0" shadow="lg">
          <MobileNavBar {...paths} />
        </Box>
      </Box>
    </>
  );
}
