import { Box, Center, Flex, For, IconButton } from "@chakra-ui/react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "@/shared/i18n";
import { Link } from "@/shared/ui";
import { Paths, getButtons } from "./getButtons";
import { locales } from "./locales";

export function DesktopNavBar(paths: Paths) {
  const buttons = getButtons(paths);

  const t = useTranslation(locales);

  const location = useLocation();

  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <Flex
      alignItems="center"
      justify="flex-start"
      bg="white"
      direction="column"
      height="full"
      overflow="hidden"
      onMouseEnter={() => setIsCollapsed(false)}
      onMouseLeave={() => setIsCollapsed(true)}
      maxWidth={isCollapsed ? "70px" : "300px"}
      transition="max-width 0.3s ease"
    >
      <For each={buttons}>
        {(button, index) => (
          <Link
            key={index}
            to={button.path}
            w="full"
            justifyContent="flex-start"
          >
            <IconButton
              variant="ghost"
              height="auto"
              color={
                location.pathname === button.path ? "primary" : "secondary"
              }
            >
              <Center p="4" justifyContent="center" aspectRatio="square">
                <button.icon size="1.5rem" />
              </Center >
              {!isCollapsed && <Box paddingEnd="4" w="full">{t(button.locale)}</Box>}
            </IconButton>
          </Link>
        )}
      </For>
    </Flex>
  );
}
