// When updating the theme, don't forget to do yarn chakra:typegen
import { SystemConfig, createSystem, defaultConfig } from "@chakra-ui/react";

/*
Import mergeAndModify must be direct. Not via index.ts.
Otherwise, the assembly of typings breaks.
https://github.com/chakra-ui/chakra-ui/issues/9078
*/
import { mergeAndModify } from "@/shared/lib/mergeAndModify";

import { buttonRecipe } from "./recipes/buttonRecipe";
import { inputRecipe } from "./recipes/inputRecipe";


const config = {
  theme: {
    __delete: ["breakpoints"],
    breakpoints: {
      desktop: "992px",
    },
    recipes: {
      __delete: ["input", "button"],
      input: inputRecipe,
      button: buttonRecipe,
    },
    semanticTokens: {
      colors: {
        fg: {
          error: { value: "#EA0000" }
        },
        bg: {
          DEFAULT: {
            value: { _light: "#FFFFFF", _dark: "#000015" }
          },
          subtle: {
            value: { _light: "#D1EBFF", _dark: "#000C34" }
          }
        },
        primary: { value: "#1B85E8" },
        secondary: { value: "#75BCFF" }
      },
    }
  },
}

const mergedConfig = mergeAndModify(defaultConfig, config) as unknown as SystemConfig;

export const system = createSystem(mergedConfig);