import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { AxiosRequestConfig } from "axios";
import { useMemo } from "react";
import { ApiError, ErrorHandlers, defaultHandlers, handleAxiosError, handleResponseError, defaultErrorCodes } from "./ApiError";
import { useApiContext } from "./ApiProvider";

export type MutationOptions<
  TData = unknown,
  TVariables = void,
  TContext = unknown,
> = Omit<
  UseMutationOptions<TData, ApiError, TVariables, TContext>,
  "mutationFn"
> & { axiosOptions: (variables: TVariables) => AxiosRequestConfig, errorHandlers?: ErrorHandlers<string> };

export function useApiMutation<
  TData = unknown,
  TVariables = void,
  TErrorCodes extends string = string,
  TContext = unknown,
>(
  mutationOptions: MutationOptions<TData, TVariables, TContext>,
) {
  const { axiosInstance } = useApiContext();
  const { axiosOptions, errorHandlers: customErrorHandlers = {} as ErrorHandlers<TErrorCodes>, ...mutationOptionsRest } = mutationOptions || {}; 

  const errorHandlers = useMemo(() => Object.assign({}, defaultHandlers, customErrorHandlers), [customErrorHandlers]);

  const mutation = useMutation({
    mutationFn: async (variables: TVariables) => {
      try {
        const response = await axiosInstance(axiosOptions(variables));
        handleResponseError<TErrorCodes>(errorHandlers, response);
        return response.data as TData;
      } catch (error) {
        handleAxiosError<TErrorCodes>(error as Error, errorHandlers);
        throw error;
      }
    },
    ...mutationOptionsRest,
  });

  const errorCode = useMemo(() => mutation.error?.errorCode || null, [mutation.error]);

  return {
    isErrorHandled: !!errorCode,
    errorCode: errorCode as TErrorCodes | defaultErrorCodes,
    ...mutation,
  };
}