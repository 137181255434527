export const locales = {
  en: {
    home: "Home",
    transactions: "Transactions",
    newTransaction: "New Transaction",
    profile: "Profile",
    settings: "Settings",
  },
  ru: {
    home: "Главная",
    transactions: "Транзакции",
    newTransaction: "Новая транзакция",
    profile: "Профиль",
    settings: "Настройки",
  },
};