import { Input, Text, Group, Flex, Box } from "@chakra-ui/react";
import { useMemo } from "react";
import { useExpressionInput, useChakraUiTextWidthMeasure } from "@/shared/lib";
import { Avatar, Field } from "@/shared/ui";

interface UserInputProps {
  name: string;
  share: number;
  ratio: number;
  ratioWidth?: number;
  hue: number;
  onValueChange: (newValue: number) => void;
  onRatioChange: (newRatio: number) => void;
  onRatioStringChange?: (newRatio: string) => void;
}

export function UserInput({
  name,
  share,
  ratio,
  ratioWidth,
  onValueChange,
  onRatioChange,
  onRatioStringChange,
  hue,
}: UserInputProps) {
  const { inputValue: shareInput, handleChange: handleShareChange, refreshValue: refreshShareValue, evaluatedValue } = useExpressionInput(share, onValueChange);
  const { inputValue: ratioInput, handleChange: handleRatioChange, refreshValue: refreshRatioValue } = useExpressionInput(ratio, onRatioChange, onRatioStringChange);

  const getWidthForText = useChakraUiTextWidthMeasure();
  const shareWidth = useMemo(() => getWidthForText(shareInput), [getWidthForText, shareInput]);

  return (
    <Field
      width="full"
      label={
        <Flex spaceX="2" alignItems="center">
          <Avatar size="sm" name={name} src="https://bit.ly/dan-abramov" />
          <Text fontWeight="bold" color={`hsl(${hue}, 100%, 50%)`}>
            {name}
          </Text>
        </Flex>
      }
    >
      <Box width="full" height="10">
        { (shareInput === '' || (Number(shareInput) !== evaluatedValue)) &&
          <Flex position="absolute" height="10" left={shareWidth} alignItems="center" zIndex="popover">
            <Text fontSize="sm" color="primary">= {evaluatedValue}</Text>
          </Flex>
        }
        <Group attached width="full">
          <Input
            type="text"
            width="full"
            onBlur={refreshShareValue}
            value={shareInput}
            onChange={handleShareChange}
            style={{
              marginRight: "2px",
            }}
          />
          <Input
            type="text"
            value={ratioInput}
            width={ratioWidth || "20%"}
            minWidth="auto"
            textAlign="center"
            onChange={handleRatioChange}
            onBlur={refreshRatioValue}
          />
        </Group>
      </Box>
    </Field>
  );
}
