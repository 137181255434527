import {
  Box,
  Button,
  Center,
  Fieldset,
  Flex,
  Input,
  Spinner,
} from "@chakra-ui/react";
import { useState } from "react";
import { useSignIn } from "@/entities/auth";
import { useTranslation } from "@/shared/i18n";
import { Field, PasswordInput } from "@/shared/ui";
import { locales } from "./locales";

export function SignInPage({ onSuccess }: { onSuccess: () => void }) {
  const [nickname, setNickname] = useState("");
  const [password, setPassword] = useState("");

  const t = useTranslation(locales);

  const {
    mutate: signIn,
    isError,
    isPending,
    errorCode,
  } = useSignIn({
    onSuccess
  });

  const handleSignIn = () => {
    signIn({ nickname, password });
  };

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Fieldset.Root gap="4" invalid={isError}>
        <Fieldset.Legend>{t('title')}</Fieldset.Legend>
        <Fieldset.Content>
          <Field label={t('nickname')}>
            <Input
              name="nickname"
              type="text"
              value={nickname}
              onChange={(e) => setNickname(e.target.value)}
            />
          </Field>

          <Field label={t('password')}>
            <PasswordInput
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Field>
          <Button
            type="submit"
            width="full"
            onClick={handleSignIn}
          >
            {t('buttonText')}
          </Button>
        </Fieldset.Content>

        <Fieldset.ErrorText>{t(`errors.${errorCode}`)}</Fieldset.ErrorText>
      </Fieldset.Root>
      {isPending && (
        <Box pos="absolute" inset="0" bg="bg/80">
          <Center h="full">
            <Spinner color="primary" />
          </Center>
        </Box>
      )}
    </Flex>
  );
}
