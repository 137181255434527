import { SystemStyleObject, useRecipe } from "@chakra-ui/react";
import { useRef, useEffect, useMemo, useCallback } from "react";

interface FixedSystemStyleObject {
  '@layer recipes': SystemStyleObject;
}

export function useChakraUiTextWidthMeasure(variantProps?: Parameters<ReturnType<typeof useRecipe<'input'>>>[0]) {
  const recipe = useRecipe({ key: "input" });
  const styles = useMemo(() => recipe(variantProps), [recipe, variantProps]) as unknown as FixedSystemStyleObject;

  const spanRef = useRef<HTMLSpanElement | null>(null);

  const prepareSpan = useCallback(() => {
    spanRef.current = document.createElement("span");
    document.body.appendChild(spanRef.current);
    Object.assign(spanRef.current.style, styles["@layer recipes"]);
    spanRef.current.style.whiteSpace = 'nowrap';
    spanRef.current.style.position = 'absolute';
    spanRef.current.style.left = '-9999px';
    spanRef.current.style.visibility = 'hidden';
    spanRef.current.style.width = 'auto';

    return spanRef.current;
  }, [styles]);

  useEffect(() => {
    return () => {
      if (spanRef.current && spanRef.current.parentNode) {
        spanRef.current.parentNode.removeChild(spanRef.current);
        spanRef.current = null;
      }
    };
  }, []);

  const getTextWidth = useCallback((text: string): number => {
    const span = spanRef.current ||  prepareSpan();

    span.textContent = text;
    return span.getBoundingClientRect().width;
  }, [prepareSpan]);

  return getTextWidth;
}
