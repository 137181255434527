import { Skeleton, Text } from "@chakra-ui/react";
import { useGetCurrentUser } from "@/entities/user";
import logo from "./logo.svg";
import "./HomePage.css";

export function HomePage() {
  const { data, isLoading, isError, errorCode } = useGetCurrentUser();

  return (
    <div className="App">
      <header className="App-header border">
        <img src={logo} className="App-logo" alt="logo" />
        <Skeleton height="10" loading={isLoading}>
          <Text>{isError ? errorCode : data?.nickname}</Text>
        </Skeleton>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}
