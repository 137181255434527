import { evaluate } from "mathjs";
import { useState, useEffect, useMemo, useRef, useCallback } from "react";

export function useExpressionInput(value: number, onChange: (newValue: number) => void, onStringChange?: (newValue: string) => void, precision = 2) {
  const [inputValue, setInputValue] = useState(value.toString());
  const evaluatedValueRef = useRef(value);

  const evaluateValue = useCallback((expression: string) => {
    try {
      let value = expression ? evaluate(expression) : 0;

      if(typeof value !== 'number') return evaluatedValueRef.current;

      const factor = Math.pow(10, precision);
      value = Math.round(value * factor) / factor;

      evaluatedValueRef.current = value;
      return value;
    } catch (_e) {
      return evaluatedValueRef.current;
    }
  }, [precision]);

  const evaluatedValue = useMemo(() => evaluateValue(inputValue), [evaluateValue, inputValue]);

  const refreshValue = useCallback(() => {
    const newValue = value.toString();

    setInputValue(newValue);
    onStringChange?.(newValue);
  }, [onStringChange, value]);

  useEffect(() => {
    if (evaluatedValue !== value) refreshValue();
  }, [evaluatedValue, refreshValue, value]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    onStringChange?.(newValue);

    const evaluatedValue = evaluateValue(newValue);
    if(evaluatedValue !== value) onChange(evaluatedValue);
  }, [evaluateValue, onChange, onStringChange, value]);


  return { inputValue, handleChange, evaluatedValue, refreshValue };
}